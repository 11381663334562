import Main from "../Main/Main";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addHelpAndSupportIssue } from "../../Redux/Actions/user/userAll";
import { toast } from "react-toastify";

export default function HelpSupport() {
  const dispatch = useDispatch();

  const [issueType, setIssueType] = useState("");
  const [description, setDescription] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = {
      issue_type: issueType,
      desc: description,
    };

    try {
      const res = await dispatch(addHelpAndSupportIssue(formData));

      if (res?.payload?.user?.success) {
        toast.success(
          res?.payload?.data?.message || "Issue submitted successfully!"
        );
        setIssueType("");
        setDescription("");
      } else {
        toast.error(
          res?.payload?.data?.message ||
            "Failed to submit the issue. Please try again."
        );
      }
    } catch (error) {
      console.error("Submission error:", error);
      toast.error("An unexpected error occurred.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Main>
      <div className="row">
        <div class="col-xl-7 col-12 ">
          <div class="new_consultation_booking  faq-contact-main bg-white">
            <h2 class=" commom-prop common_title padding_36">Contact Us</h2>

            <form onSubmit={handleSubmit} className="faq-contact-box">
              <div className="faq-contact-box">
                <div className="">
                  <label>Issue Type</label>
                  <select
                  className="custom-select"
                    style={{ border: "none" }}
                    value={issueType}
                    onChange={(e) => setIssueType(e.target.value)}
                    required
                  >
                    <option value="">Select an issue type</option>
                    <option value="1">
                      I have a problem regarding a ToothAid member
                    </option>
                    <option value="2">I have a problem with my account</option>
                    <option value="3">I have a problem with billing</option>
                    <option value="4">I have a technical issue</option>
                    <option value="5">
                      I have a suggestion for improvement
                    </option>
                    <option value="6">Something Else</option>
                  </select>
                </div>
                <div className="">
                  <label>Description</label>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                  ></textarea>
                </div>
                <div>
                  <button
                    type="submit"
                    className="launch-btn"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* <div class="col-xl-5 col-12 ">
          <div class="new_consultation_booking common_shadow bg-white">
            <h2 class=" commom-prop common_title padding_36">FAQs</h2>
            <div class="row p-0">
              <div class="col-12 faq-w-contact">
                <Accordion defaultActiveKey="0" flush>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      1. What is an emergency dental online consultation?
                    </Accordion.Header>
                    <Accordion.Body>
                      An emergency dental online consultation allows you to
                      connect with a licensed dentist via video or chat to
                      discuss urgent dental issues. The dentist can assess your
                      symptoms, provide advice, and, if needed, prescribe
                      medications.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      2. How quickly can I speak to a dentist?
                    </Accordion.Header>
                    <Accordion.Body>
                      Most consultations are available within hours or the next
                      day, but wait times may vary depending on demand. You can
                      typically connect with a dentist within 24 hours.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      3. Can I get a prescription from an online dental
                      consultation?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, if the dentist determines that medication is
                      necessary, they can send a prescription to you for
                      antibiotics, painkillers, or other medications.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      4. What types of dental issues can be addressed online?
                    </Accordion.Header>
                    <Accordion.Body>
                      Common issues addressed include toothaches, gum
                      infections, broken or chipped teeth, swelling,
                      sensitivity, and advice on managing pain. For severe
                      problems like major trauma or large swellings, you’ll need
                      to see a dentist in person.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      5. Is the online symptom checker accurate?
                    </Accordion.Header>
                    <Accordion.Body>
                      The symptom checker is designed to provide general
                      information and advice based on your input. It is not a
                      substitute for professional diagnosis, so it’s recommended
                      to follow up with a dentist for a more accurate
                      assessment.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      6. Can children use the service?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, parents or guardians can schedule an online
                      consultation for children. The dentist can offer advice
                      and treatment for common paediatric dental issues.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      7. What if I need follow-up care after the consultation?
                    </Accordion.Header>
                    <Accordion.Body>
                      If the issue requires further treatment, the dentist can
                      refer you to a local dental office or recommend follow-up
                      steps. In many cases, they will give advice on how to
                      manage the problem until you can see a dentist in person.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      8. Is my insurance accepted for online consultations?
                    </Accordion.Header>
                    <Accordion.Body>
                      Some insurance providers cover online consultations. Check
                      with your insurance company or the website to confirm if
                      your plan includes telehealth dental services.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      9. How much does an online consultation cost?
                    </Accordion.Header>
                    <Accordion.Body>
                      The cost of an initial emergency virtual consultation is
                      £20. This does not include the cost of a prescription if
                      this is required.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="9">
                    <Accordion.Header>
                      10. Can I use this service if I’m traveling?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, you can use the service from anywhere as long as you
                      have internet access. Prescriptions will be sent to you
                      where you can collect from a pharmacy near you in the UK.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="10">
                    <Accordion.Header>
                      11. What should I do if I experience severe dental pain
                      but cannot access a dentist immediately?
                    </Accordion.Header>
                    <Accordion.Body>
                      If you cannot access a dentist in person right away, the
                      online consultation service can provide immediate advice
                      and help manage pain or infection with prescribed
                      medications.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="11">
                    <Accordion.Header>
                      12. How secure is my personal information?
                    </Accordion.Header>
                    <Accordion.Body>
                      The website uses secure, encrypted systems to protect your
                      personal information and medical data, ensuring compliance
                      with privacy regulators.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </Main>
  );
}
